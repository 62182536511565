.SkillsSection{

}

.skills-container{
    margin-top:4rem 0;
    .skill-title{
        font-size: 1.4rem;
        font-weight: 400;
    }

    .skill-bar{
        display: flex;
        align-items: center;
        .skill-text{  
        }
        .skill-progress{
            width: 100%;
            .progress{
                width: 100%;
                height: .5rem;
                background-color: red;
                margin-left: 1rem;
                background-color: #f9b108ad;
                position: relative;
                .inner-progress{
                    position: absolute;
                    background-color: #F9B208;
                    height: 100%;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }
}