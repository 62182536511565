.App{
    .sidebar{
        width: 16%;
        height: 100vh;
        // background-color: #FC5404;
        position: fixed;
        z-index: 11;
        border-right: 1px solid #F9B208;
        transform-origin: left; 
        @media screen and (max-width: 1000px){
            transform: translateX(-100%);
            transition: all .4s ease-in-out;
            width: 20%;
        }
        @media screen and (max-width: 411px){
            width: 20%;
        }
    }
    .nav-toggle{
        @media screen and (max-width: 1000px){
            transition: all .4s ease-in-out;
            transform: translateX(0);
        }
    }

    .main-content{
        width: 84%;
        margin-left: 16%;
        // background-color: #FF4500;
        min-height: 100vh;
        display: grid;
        position: relative;
        .content{
            margin: 5rem 15rem;
            @media screen and (max-width: 1100px){
               margin: 2rem 10rem;
            } 
            @media screen and (max-width: 1400px){
                margin: 3rem 10rem;
            }
            @media screen and (max-width: 900px){
                margin: 3rem 10rem;
            }
            @media screen and (max-width: 710px){
                margin: 2rem 3rem;
            }
        }
        @media screen and (max-width: 1000px){
            margin-left: 0;
            width: 100%;
        }
    }
}

.nav-btn{
    position: absolute;
    z-index: 10;
    right: 10%;
    top: 10%;
    
    width: 5rem;
    height: 5rem;
    display: none;
    display: flex; 
    align-items: center;
    cursor: pointer;
    
    
    justify-content: space-between;
    .lines-1, .lines-2,.lines-3{
        height: .4rem;
        width: 100%;
        background-color: #F9B208;
        pointer-events: none;
        display: none;
        border-radius: 20px;
        &:not(:last-child){
            margin-bottom: .5rem;
        }
    } 
    @media screen and (max-width: 1000px){
        display: block;
        .lines-1, .lines-2, .lines-3{
            display: block;
        }
    }
}