.ContactItem {
    width: 100%;
    &:not(:last-child){
        margin-bottom: 1rem;
    }
    .contact {
        display: flex;
        align-items: center;
        height: 10rem;
        background-color: #f6b93b;
        padding: 3rem 3rem;
        .right-items {
            margin-left: 1rem;
        }
        img{
            padding: 1rem;
            border: 1px solid #F9B208;
            margin-left: 2rem;
            width: 16%;
        }
        .right-items{
            h6{
                font-size: 1.4rem;
                font-weight: 500;
            }
        }
    }
}

.ContactPage {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 6rem;
    @media screen and (max-width: 768px){
        grid-template-columns: repeat(1, 1fr);
        .map-sect{
            width: 100%;
            height: 50vh;
            margin-bottom: 1rem;
        }
    }
    .contact-sect{
        width: 100%;
    }
}

.map-sect{
    width: 97%;
    padding: 1rem;
    background-color: #f6b93b;
    iframe{
        width: 100%;
        height: 100%;
    }
}

.contact-sect{
    width: 100%;
}

.title0{
    margin-bottom: 5rem;
}