.NavBar{
    height: 100vh;
    .nav{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .profile{
            width: 100%;
            text-align: center;
            margin-top: 1rem;
            border-bottom: 1px solid #F9B208;
            img{
                width: 70%;
                border-radius: 100%;
                border: 5px solid #F9B208;
                margin-bottom: 1rem; 
            }
        }

        .nav-items{
            width: 100%;
            .nav-item{
                list-style: none;
                text-align: center;
                position: relative;
                a{
                    text-decoration: none;
                    font-size: inherit;
                    color: black;
                    display: block;
                    padding: .5rem 0;
                    position: relative;
                    &::before{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: .4rem;
                        height: 100%;
                        background-color: #0381ff33;
                        transform-origin: bottom;
                        transform: scale(0);
                        overflow-x: hidden;
                        transition: transform .6s, .2s width .5s cubic-bezier(1, -0.16,0,1.32); 
                    }

                    &:hover::before{
                        width: 100%;
                        transform: scale(1);
                    }
                }
            }
        }

        .footer{
            color: black;
            width: 100%;
            border-top: 1px solid #F9B208;
            padding-top: 1rem;
            p {
                text-align: center;
                padding: 1rem 0;
            }
        }
    }
}

.active{
    background-color: #F98404;
}