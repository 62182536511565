.Title {
    text-transform: uppercase;
    font-size: 2rem;
    color: #f6b93b;
    position: relative;
    padding-bottom: 1rem;
    letter-spacing: 3px;
    &::before{
        content: "";
        bottom: 0;
        position: absolute;
        left: 0;
        background-color: #F9B208;
        width: 6rem;
        height: .4rem;
        border-radius: 50px;
    }
    &::after{
        content: "";
        bottom: 0;
        position: absolute;
        left: 0;
        background-color: #F9B208;
        width: 6rem;
        height: .4rem;
        border-radius: 50px;
    }
    span {
        position: absolute;
        top: 40%;
        left: 0;
        font-size: 5rem;
        opacity: 0.07;
    }
}